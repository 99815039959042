import { FC, memo, useState } from 'react'
import {
    Box,
    Collapse,
    createStyles,
    makeStyles,
    styled,
    Typography,
} from '@material-ui/core'
import { getStatusColor } from './utils'
import Icon from './Icons/Icon'
import { Icon as EidIcon } from 'packages/eid-icons'
import ArrowIcon from './Icons/ArrowIcon'
import moment from 'moment'
import { Status } from './Status'
import { StepApproversList } from './StepApproversList'
import { Avatar } from 'packages/eid-ui'
import { IApprovalStep } from 'types'
import { getFullImageUrl } from 'utils'
import { RiskViolationLeftRight } from '../V2/RiskViolationLeftRight'
import TextFormatter from 'components/TextFormatter'
import Approver from './Approver'
import { useTranslation } from 'react-i18next'
import { ArrowIcon as EidArrowIcon } from 'packages/eid-icons'
import { Button } from 'components'
import { RiskNode } from './RiskNode'
import classNames from 'classnames'

const useStyles = makeStyles(
    createStyles({
        root: {
            marginTop: '12px',
            marginBottom: '12px',
            paddingTop: '8px',
            paddingBottom: '8px',
            position: 'relative',
            '& *': {
                zIndex: 2,
            },
        },
        rootExpanded: {
            '& *': {
                zIndex: 3,
            },
        },
        nonRiskApproverNode: {},
        riskApproverNode: {},
        container: {
            position: 'relative',
            paddingLeft: '8px',
            paddingRight: '8px',
            background: '#fff',
            borderRadius: '8px',
            boxShadow: ' 0 4px 16px 0 rgba(0, 0, 0, 0.05)',
            border: 'solid 1px rgba(0, 0, 0, 0.11) ',
        },
        approvedBox: {
            minHeight: '100px',
            paddingTop: '12px',
            paddingBottom: '12px'
        },
        openStep: {
            backgroundColor: '#f9eada',
            border: 'solid 1px #d76e00',
            background: 'red',
        },
        showLessAndMore: {
            color: '#01ae8f',
            border: '1px solid #01ae8f',
            borderRadius: '15px',
            padding: '5px 15px 5px 10px',
            textTransform: 'uppercase',
            fontSize: '11px',
            height: '32px',
            width: '135px',
        },
        showMoreAndLess: {
            color: '#b16dd5',
            border: '1px solid #b16dd5',
            borderRadius: '15px',
            padding: '5px 15px 5px 10px',
            textTransform: 'uppercase',
            fontSize: '11px',
            height: '32px',
            width: '135px',
            '& *': {
                color: '#b16dd5',
            },
        },
    }),
)

const styles = {
    name: {
        fontSize: '14px',
        color: '#5d6870',
        fontWeight: 'bold',
    },
    position: {
        textTransform: 'uppercase',
        color: '#9b9b9b',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    status: {
        color: '#01ae8f',
        fontSize: '14px',
    },
    divider: {
        margin: '0px 10px',
        color: '#d8d8dd',
        width: '16px',
        height: '1px',
    },
}

export type StepNodeProps = {
    id?: string
    requestId: string
    itemId?: string
    approverCount: null | number
    useDataHook: any
    showAllApprovers: boolean
    setShowAllApprovers: any
    step: IApprovalStep
    rerenderArrows: () => void
    childId?: string,
    globalApprover: boolean
}

const leftMarginsMap = {
    0: '0',
    1: '56px',
    2: '112px',
}

const leftMarginsAggregatedRiskMap = {
    0: 0,
    1: 56,
    2: 112,
}

const StepNode: FC<StepNodeProps> = memo((props) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [showMore, setShowMore] = useState(false)
    const handleShowMore = (moreInfo: boolean) => {
        setShowMore(moreInfo)
        props.rerenderArrows()
    }

    const bindStepApproverNameAndStatus = (stepInfo: any, type: string) => {
        try {
            const aggregatedRisks = stepInfo.risk;
            let approverName = ""
            let isAllAutoApproved = stepInfo.autoApprove
            if (aggregatedRisks && aggregatedRisks.length > 0) {
                aggregatedRisks.map((item: any) => {
                    const approverInfo = item.riskApproversAndStatus;
                    if (approverInfo && !approverInfo.autoApprove) {
                        isAllAutoApproved = false
                        approverName = approverInfo.approverFriendlyName
                    }
                    if (approverInfo && approverInfo.autoApprove && approverInfo.status !== "Approved") {
                        isAllAutoApproved = false
                    }
                })
            } else {
                if (stepInfo.autoApprove && stepInfo.status !== "Approved") {
                    isAllAutoApproved = false
                }
            }

            if (type === "status") {
                return isAllAutoApproved
            } else {
                return stepInfo.approver.friendlyName ? stepInfo.approver.friendlyName : approverName
            }
        } catch (err) {

        }
    }

    return (
        <Box
            className={classNames(classes.root, {
                [classes.rootExpanded]: props.showAllApprovers,
                [classes.nonRiskApproverNode]:
                    !props.step.approver && !props.step.risk,
                [classes.riskApproverNode]:
                    !props.step.approver &&
                    props.step.risk &&
                    props.step.status === 'Open',
            })}
            marginLeft={leftMarginsMap[props.step.indentationLevel]}
        >
            <Box
                borderRadius="6px"
                className={classNames(classes.container, {
                    [classes.openStep]: props.step.status === 'Open',
                    [classes.approvedBox]:
                        props.step.status === 'Approved' ||
                        props.step.status === 'Rejected',
                })}
                id={!props.globalApprover ? props.id : "null"}
            >
                <Box display="flex" alignItems="center" position="relative">
                    <Box width="100%">
                        {props.step.approver ? (
                            <Box position="relative" width="100%">
                                <Box
                                    marginY="7.5px"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Box minWidth={'large'} marginRight="15px">
                                        <Avatar
                                            id={props.globalApprover ? props.id : props.childId}
                                            size={'large'}
                                            src={getFullImageUrl(
                                                props.step.approver
                                                    .imageThumbUrl,
                                            )}
                                            style={{
                                                boxShadow:
                                                    '4px 1px 3px 2px #d1cdc7',
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        {/*@ts-ignore*/}
                                        <Typography style={styles.name}>
                                            {bindStepApproverNameAndStatus(props.step, "approverName")}
                                        </Typography>

                                        {props.step.risk &&
                                            props.step.risk[0] &&
                                            props.step.risk[0].riskViolator && (
                                                <Box display={'flex'}>
                                                    <TextFormatter
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#ce2333',
                                                            fontWeight: 'bold',
                                                        }}
                                                        value={t(
                                                            'MyTasks_RiskViolator',
                                                        ) + " : " +
                                                            (props.step.risk &&
                                                                props.step.risk[0]
                                                                ? props.step
                                                                    .risk[0]
                                                                    .riskViolator
                                                                : '')
                                                        }
                                                        maxCharacters={55}
                                                    />
                                                </Box>
                                            )}

                                        <Box display="flex" alignItems="center">
                                            <TextFormatter
                                                style={{
                                                    ...styles.status,
                                                    color: getStatusColor(
                                                        props.step.status,
                                                    ),
                                                }}
                                                value={props.step.friendlyName}
                                                maxCharacters={100}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Approver
                                id={props.globalApprover ? props.id : props.childId}
                                stepId={props.step.stepId}
                                status={props.step.status}
                                hasRisk={Boolean(props.step.risk)}
                                currentStep={props.step.friendlyName}
                                showAllApprovers={props.showAllApprovers}
                                setShowAllApprovers={() =>
                                    props.setShowAllApprovers(
                                        !props.showAllApprovers,
                                    )
                                }
                                riskViolator={
                                    props.step.risk && props.step.risk[0]
                                        ? props.step.risk[0].riskViolator
                                        : ''
                                }
                            />
                        )}
                    </Box>

                    <ArrowIcon
                        variant={props.step.status}
                        style={{ margin: '0px 20px' }}
                    />

                    <Box position="relative">
                        <Icon
                            icon={props.step.status}
                            label={
                                <Status
                                    status={props.step.status}
                                    autoApprove={bindStepApproverNameAndStatus(props.step, "status")}
                                />
                            }
                        />

                        {props.step.approvedOnUtc && (
                            <DateTimeContainer>
                                <EidIcon
                                    height="16px"
                                    width="16px"
                                    name="Clock"
                                    color="#aab0b4"
                                />

                                <DateTime noWrap>
                                    {moment
                                        .utc(props.step.approvedOnUtc)
                                        .local()
                                        .format('LLL')}
                                </DateTime>
                            </DateTimeContainer>
                        )}
                    </Box>
                    <Box>
                        {props.step.showMore && (
                            <Button
                                onClick={() => handleShowMore(!showMore)}
                                className={
                                    showMore
                                        ? classes.showMoreAndLess
                                        : classes.showLessAndMore
                                }
                            >
                                {showMore
                                    ? t('MyTasks_LessDetails')
                                    : t('MyTasks_MoreDetails')}{' '}
                                <EidArrowIcon
                                    style={{ marginLeft: '4px' }}
                                    color="#01ae8f"
                                    direction={showMore ? 'up' : 'down'}
                                />
                            </Button>
                        )}
                    </Box>
                </Box>
                <Collapse
                    in={props.showAllApprovers}
                    style={{
                        zIndex: 9999,
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                    }}
                >
                    <AllApproversContainer>
                        {props.showAllApprovers && props.step.stepId && (
                            <StepApproversList
                                totalApproversCount={props.approverCount}
                                requestId={props.requestId}
                                itemId={props.itemId}
                                stepId={props.step.stepId}
                                useDataHook={props.useDataHook}
                            />
                        )}
                    </AllApproversContainer>
                </Collapse>
                <Box
                    marginLeft={`${leftMarginsAggregatedRiskMap[
                        props.step.indentationLevel
                    ] + 10
                        }px`}
                >
                    <RiskNode
                        step={props.step}
                        showMore={showMore}
                        requestId={props.requestId}
                        itemId={props.itemId}
                        id={`${props.id}`}
                    />
                </Box>
            </Box>
        </Box>
    )
})

const AllApproversContainer = styled(Box)({
    backgroundColor: '#f5f5f6',
    padding: '4px',
    maxHeight: '184px',
    overflowY: 'auto',
    borderRadius: '8px',
    border: 'solid 1px #d8d8dd',
    boxShadow: ' 0 6px 16px 0 rgba(0, 0, 0, 0.15)',
    width: '100%',
    margin: '20px 0px 16px 0px',
    position: 'absolute',
    top: '10px',
    left: '0',
    right: '0',
    zIndex: 9999
})

const DateTimeContainer = styled(Box)({
    position: 'absolute',
    left: '-10px',
    bottom: '-32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
})

const DateTime = styled(Typography)({
    paddingLeft: '5px',
    color: '#aab0b4',
    fontSize: '12px',
})

export { StepNode }
